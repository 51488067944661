import Vue from 'vue';

const VueAxe = process.env.NODE_ENV !== 'production' ? require('vue-axe') : '';

if (process.env.NODE_ENV !== 'production') {
  Vue.use(VueAxe, {
    clearConsoleOnUpdate: false,
    config: {
      // your configuration data
      rules: [
        {
          id: 'heading-order',
          enabled: true,
        },
        {
          id: 'label-title-only',
          enabled: true,
        },
        {
          id: 'link-in-text-block',
          enabled: true,
        },
        {
          id: 'region',
          enabled: true,
        },
        {
          id: 'skip-link',
          enabled: true,
        },
        {
          id: 'help-same-as-label',
          enabled: true,
        },
      ],
    },
  });
}
